import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import image from "./img.jpg";

const Page = () => {
  const  navigate  = useNavigate();
  return (
    <div className="mainPage">
      <img className="img" src={image} alt="Bild" />
      <p>Kul att ni vill vara med och fira vår dag</p>
      <Button
        type="button"
        onClick={() => {
          window.location.href = "https://forms.gle/G9oXXXX1CxYdmXBr8";
        }}
      >
        OSA HÄR ❤️
      </Button>
      <Button type="button" onClick={() => navigate("/boende")}>
        Boka boende
      </Button>
    </div>
  );
};

export default Page;
