import { useClickAway } from "react-use";
import { useRef, useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import "./Menu.css"
import { NavLink } from "react-router-dom";

const Menu = ({ routes, type }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  if (type === "mobile") {
    return (
      <div ref={ref} className="mobile">
        <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
        {isOpen && (
          <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="openMenu"
          >
            <ul className="">
              {routes.map((route) => {
                return (
                  <li key={route.title} className="">
                    <NavLink
                      onClick={() => setOpen(false)}
                      to={route.path}
                      relative="false"
                    >
                      {route.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </motion.nav>
        )}
      </div>
    );
  }
  return (
    <div className="desktop">
      <nav>
        {routes.map((route) => {
          return (
            <NavLink to={route.path} key={route.title}>
              {route.title}
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};
export default Menu;
